<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :content-class="$vuetify.breakpoint.mdAndDown ? undefined : 'rounded-xl'"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <vue-html2pdf
      :enable-download="true"
      :manual-pagination="true"
      pdf-content-width="1200px"
      :pdf-quality="2"
      filename="QR_Code"
      pdf-format="a3"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
        />
        <div
          :style="`
            background: url('${
              template.var.qrcodeplakat_sportler
                ? template.var.qrcodeplakat_sportler
                : template.var.qrcodeplakat
            }');
            background-size: cover;
            background-position: bottom center;
            width: 1110px;
            height: 1576px;
            margin-top: 10px;
            padding-top: 140px;
            padding-left: 120px;
            padding-right: 120px;
          `"
        >
          <div
            style="
              font-family: Roboto;
              color: #000000;
              text-align: center;
              width: 850px;
              padding-left: 145px;
              padding-right: 145px;
              padding-top: 125px;
              padding-bottom: 50px;
            "
          >
            <div
              style="
                position: absolute;
                margin: 230px;
                background-color: #fff;
                width: 140px;
                height: 140px;
              "
            >
              <img :src="user.data.verein.logo" style="height: 95%;" />
            </div>
            <qrcode-vue :value="link" size="600" level="H"></qrcode-vue>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>LINK FÜR ATHLETEN</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row
        justify="center"
        class="mt-4"
        v-if="veranstaltung.data.disziplinen && disziplinen === 0"
      >
        <v-col cols="11" class="text-center">
          <span class="font-weight-bold">
            Bitte erstelle Disziplinen für diesen Wettkampf damit Athleten sich
            für diese anmelden können.
          </span>
        </v-col>
        <v-col cols="11" class="text-center">
          <v-btn
            rounded
            :color="template.colors.primary"
            large
            class="my-5"
            @click="closing()"
          >
            OK
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-4"
        v-if="!veranstaltung.data.disziplinen || disziplinen !== 0"
      >
        <v-col cols="11" class="text-center">
          <span class="font-weight-bold">
            Athleten dieser Veranstaltung können ihre Kontaktdaten über den
            folgenden Link eingeben:
          </span>
        </v-col>
        <v-col cols="11" class="text-center">
          <v-text-field
            filled
            rounded
            readonly
            label="Link für Athleten"
            id="linkcopy"
            :value="link"
          ></v-text-field>
          <v-btn
            :disabled="linkcopysuccess"
            :block="$vuetify.breakpoint.smAndDown"
            large
            rounded
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="copylink()"
          >
            <v-icon class="mr-2">
              {{ linkcopysuccess ? 'mdi-check-bold' : 'mdi-content-copy' }}
            </v-icon>
            {{ linkcopysuccess ? 'Link kopiert' : 'Link kopieren' }}
          </v-btn>
        </v-col>
        <v-col cols="11" class="text-center">
          <v-divider class="my-5"></v-divider>
          <v-btn
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            :block="$vuetify.breakpoint.smAndDown"
            large
            rounded
            class="mt-4"
            :loading="generating"
            @click="generatePDF()"
            target="_blank"
          >
            <v-icon class="mr-2">mdi-download</v-icon>
            QR-Code-PDF Download
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../../../firebaseConfig'
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'
import template from '../../../../../styles/template'

export default {
  name: 'Zuschauer-QR-Link',
  data() {
    return {
      linkcopysuccess: false,
      generating: false,
      link: '',
      disziplinen: 0,
    }
  },
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  mounted() {
    if (this.veranstaltung.data) {
      this.link =
        this.template.var.app_url +
        '/wk/' +
        this.user.data.alias +
        '/' +
        this.veranstaltung.id

      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Disziplin')
        .get()
        .then((snap) => {
          snap.forEach((disziplinen) => {
            this.disziplinen++
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generatePDF() {
      this.generating = true
      this.$refs.html2Pdf.generatePdf()
      this.$emit('generated', true)
      setTimeout(() => {
        this.generating = false
      }, 4000)
    },
    copylink() {
      let testingCodeToCopy = document.querySelector('#linkcopy')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.linkcopysuccess = true
        setTimeout(() => {
          this.linkcopysuccess = false
        }, 6000)
      } catch (err) {
        alert('Oops, unable to copy')
      }

      window.getSelection().removeAllRanges()
    },
    get_sportstaette(name) {
      var i
      var out = ''
      if (name) {
        for (i = 0; i < this.user.data.sportstaetten.length; i++) {
          var place = this.user.data.sportstaetten[i]
          if (name == place.name) {
            if (!place.main) {
              out = '/' + place.link
            }
          }
        }
      }
      return out
    },
  },
}
</script>
