<template>
  <div>
    <v-row
      style="max-height: 70vh;"
      justify="center"
      v-if="veranstaltung.loader"
    >
      <v-col cols="10" align-self="center" class="text-center">
        <h2>Wettkampf wird geladen</h2>
        <v-progress-circular
          :size="50"
          :width="7"
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      v-if="!veranstaltung.loader && veranstaltung.data"
      justify="center"
      class="py-5 mt-5"
    >
      <v-col
        cols="12"
        :style="`color: ${template.colors.inline_primary_text};`"
        v-if="!veranstaltung.data.gastgeberverein"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon to="/start">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center" align-self="center">
            <v-icon x-large>
              mdi-podium
            </v-icon>
          </v-col>
          <v-col>
            <h3 class="white--text font-weight-light">
              {{ veranstaltung.data.art }}
            </h3>
            <h2
              class="font-weight-bold"
              :style="`template: ${template.colors.inline_primary_text};`"
            >
              {{ veranstaltung.data.name }}
            </h2>
            <h4 :style="`color: ${template.colors.inline_background_text};`">
              {{
                veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum
                  ? 'AM'
                  : 'VOM'
              }}
              {{
                veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum
                  ? parseDate(veranstaltung.data.beginn.datum)
                  : parseDate1(veranstaltung.data.beginn.datum)
              }}
              {{
                veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum
                  ? 'VON'
                  : 'BIS'
              }}
              {{
                veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum
                  ? veranstaltung.data.beginn.uhrzeit +
                    ' BIS ' +
                    veranstaltung.data.ende.uhrzeit +
                    ' UHR'
                  : parseDate(veranstaltung.data.ende.datum)
              }}
              <span
                class="ml-2 font-weight-bold"
                v-if="
                  veranstaltung.data.sportstaette && $vuetify.breakpoint.mdAndUp
                "
              >
                <v-icon>mdi-map-marker</v-icon>
                {{ veranstaltung.data.sportstaette }}
              </span>
            </h4>
            <h4
              v-if="
                veranstaltung.data.sportstaette && $vuetify.breakpoint.smAndDown
              "
            >
              <v-icon>mdi-map-marker</v-icon>
              {{ veranstaltung.data.sportstaette }}
            </h4>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="false">
          <v-col cols class="text-center" v-if="veranstaltung.data">
            <span class="font-weight-bold" v-if="veranstaltung.anwesenheit">
              {{
                veranstaltung.data.anwesende_personen
                  ? veranstaltung.data.anwesende_personen
                  : veranstaltung.anwesenheit.length
              }}
              <small>/{{ veranstaltung.data.maxzuschauer }}</small>
            </span>
            <br />
            <span class="font-weight-light">TEILNEHMER</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols class="text-center">
            <span class="font-weight-bold">
              {{ parseDate(veranstaltung.data.beginn.datum) }}
            </span>
            <br />
            <span class="font-weight-light">
              {{ veranstaltung.data.beginn.uhrzeit }} BIS
              {{ veranstaltung.data.ende.uhrzeit }} UHR
            </span>
          </v-col>
        </v-row>
        <v-row class="pa-0 mt-4" justify="center" style="padding-bottom: 5vh;">
          <v-col
            cols="6"
            class="py-0"
            v-if="
              !veranstaltung.data.abgesagt &&
              veranstaltung.data.valid &&
              veranstaltung.data.disziplinen
            "
          >
            <cardbutton
              :title="
                template.var.verband == 'DEU'
                  ? 'Gruppen<br><br>'
                  : 'Disziplinen<br><br>'
              "
              :icon="'mdi-medal'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${$route.params.veranstaltung}/disziplinen`"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="!veranstaltung.data.abgesagt && veranstaltung.data.valid"
          >
            <cardbutton
              :title="'Anmeldungen<br>verwalten'"
              :icon="'mdi-account-group'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${$route.params.veranstaltung}/anmeldungen`"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="!veranstaltung.data.abgesagt && veranstaltung.data.valid"
          >
            <cardbutton
              :title="'Athleten<br>QR-Code & Link'"
              :icon="'mdi-qrcode'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              @click="athletenlink = true"
            />
            <AthletenLink
              v-if="athletenlink"
              v-on:closing="athletenlink = false"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="
              veranstaltung.data.maxzuschauer > 0 &&
              !veranstaltung.data.abgesagt &&
              veranstaltung.data.valid
            "
          >
            <cardbutton
              :title="'Wettkampf<br>kontrolle'"
              :icon="'mdi-tablet-dashboard'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${veranstaltung.id}/wettkampfkontrolle${veranstaltung.data.sportstaettenobj.indexlink}`"
            />
          </v-col>
          <v-col cols="6" class="py-0" v-if="!veranstaltung.data.abgesagt">
            <cardbutton
              :title="'Anwesenheitsliste<br>ansehen'"
              :icon="'mdi-clipboard-list'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${veranstaltung.id}/anwesenheitsliste`"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="!veranstaltung.data.abgesagt && veranstaltung.data.valid"
          >
            <cardbutton
              :title="'Wettkampf<br>bearbeiten'"
              :icon="'mdi-cogs'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${$route.params.veranstaltung}/bearbeiten`"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="
              !veranstaltung.data.abgesagt && veranstaltung.data.valid && false
            "
          >
            <cardbutton
              :title="'Zuschauer<br>QR-Code & Link'"
              :icon="'mdi-qrcode'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              @click="zuschauerinfo = true"
            />
            <ZuschauerInfo
              v-if="zuschauerinfo"
              v-on:closing="zuschauerinfo = false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        :style="`color: ${template.colors.inline_primary_text};`"
        v-if="veranstaltung.data.gastgeberverein"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn text to="/start">
              <v-icon large>mdi-chevron-left</v-icon>
              ZURÜCK
            </v-btn>
          </v-col>
        </v-row>
        <v-card dark class="rounded-xl pa-0">
          <v-row justify="center" class="my-0">
            <v-col cols="12" align-self="center" class="py-0">
              <v-card
                class="rounded-xl"
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
              >
                <v-row justify="center">
                  <v-col cols="12" class="text-center pb-0">
                    <h4>{{ veranstaltung.data.art }}</h4>
                  </v-col>
                  <v-col class="text-center" align-self="center">
                    <v-avatar tile>
                      <v-img
                        :src="veranstaltung.data.gastgeberverein.logo"
                      ></v-img>
                    </v-avatar>
                    <p class="mb-0">
                      {{ veranstaltung.data.gastgeberverein.name }}
                    </p>
                    <small>{{ veranstaltung.data.gastmannschaft }}</small>
                  </v-col>
                  <v-col cols="auto" class="text-center" align-self="center">
                    <p>
                      {{ parseDate(veranstaltung.data.beginn.datum) }}
                      <br />
                      <span class="font-weight-bold">
                        {{ veranstaltung.data.beginn.uhrzeit }} Uhr
                      </span>
                    </p>
                  </v-col>
                  <v-col class="text-center" align-self="center">
                    <v-avatar tile>
                      <v-img :src="user.data.verein.logo"></v-img>
                    </v-avatar>
                    <p class="mb-0">{{ user.data.verein.name }}</p>
                    <small>{{ veranstaltung.data.mannschaft }}</small>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-row
          class="pa-0 mt-4"
          justify="center"
          style="max-height: 70vh; overflow-y: scroll; padding-bottom: 5vh;"
        >
          <v-col
            cols="12"
            class="py-0"
            v-if="
              veranstaltung.data.art != 'Wettkampf' &&
              !veranstaltung.data.abgesagt
            "
          >
            <cardbutton
              :title="'Team<br>Anwesenheit'"
              :icon="'mdi-playlist-check'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              chev
              :to="`/veranstaltung/${$route.params.veranstaltung}/team-erfassen`"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import ZuschauerInfo from '../../zuschauer-info'
import AthletenLink from './athleten-link'
import store from '../../../../../store'
import router from '../../../../../routes/index'
import cardbutton from '../../../../../elements/cards/card-button'

export default {
  name: 'Veranstaltung',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      athletenlink: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      disziplinen: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  components: {
    ZuschauerInfo,
    AthletenLink,
    cardbutton,
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parseDate1(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.'
    },
    veranstaltung_absagen() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          abgesagt: true,
        })
        .then(() => {
          router.push('/start')
        })
    },
    veranstaltung_reaktivieren() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          abgesagt: false,
        })
    },
  },
}
</script>
